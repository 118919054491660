const cleanArrValues = (values = []) =>
  values.filter(val => {
    const isZero = val === 0;
    return isZero || val;
  });

export const sum = (values = []) =>
  cleanArrValues(values).reduce((acc, curr) => acc + parseFloat(curr), 0);
export const average = (values = []) => {
  const innerSum = sum(cleanArrValues(values));
  const isNotANumber = Number.isNaN(innerSum);
  // console.log({ isNotANumber });
  if (isNotANumber) {
    return innerSum;
  }
  return innerSum / values.length;
};
export const valueFromMatrixRow = ({ row, matrix }) => {
  if (!matrix || !row) {
    console.log('No row or matrix specified');
    return null;
  }
  const isArray = Array.isArray(matrix);
  if (!isArray) {
    console.log('Matrix not valid');
    return null;
  }
  const isRowField = typeof row === 'string';
  if (!isRowField) {
    console.log('Row field not valid');
    return null;
  }
  // get response from row object
  return matrix.find(r => r.value === row).response;
};

export const evalTextFunction = functionBody => {
  try {
    // eslint-disable-next-line no-eval
    const parsedFns = eval(functionBody);
    return parsedFns;
  } catch (err) {
    console.log(err);
  }
  return () => {};
};
