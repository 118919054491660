import { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { RadioButtonsWrapper, ButtonsGroup } from '@/styles/questions.styles';
import { generateOptions } from '@/utils/index';
import { Icon } from 'react-icons-kit';
import { ic_radio_button_checked as iconChecked } from 'react-icons-kit/md/ic_radio_button_checked';
import { ic_radio_button_unchecked as iconUnchecked } from 'react-icons-kit/md/ic_radio_button_unchecked';
import QuestionTypeSelector from './Shared/QuestionTypeSelector';
import QuestionSettingsDrawer from './Shared/QuestionSettingsDrawer';
// import QuestionEditorHeader from './Shared/QuestionEditorHeader';
import QuestionOptionsEditor from './Shared/QuestionOptionsEditor';
import QuestionError from './Shared/QuestionError';

const RadioButtons = props => {
  const {
    type,
    name,
    label,
    title,
    options,
    control,
    rules = {},
    helper,
    error,
    value,
    cols = 1,
    labelsTop = [],
    labelsBottom = [],
    isVisible = true,
    editable,
    onChange,
    onDuplicate,
    onDelete
  } = props;
  // console.log({ props });

  // if (editable) {
  //   console.log({ props });
  // }

  // handle automatic title input focus on selected question
  const titleRef = useRef();
  useEffect(() => {
    if (editable) {
      if (titleRef.current) {
        titleRef.current.focus();
      }
    }
  }, [editable]);

  const parsedOptions = generateOptions(options);
  const isStackableOnMobile = parsedOptions.length > 5;

  if (!isVisible) {
    return null;
  }

  return (
    <RadioButtonsWrapper className="radio-buttons-question" errorState={error}>
      {/* {editable && <QuestionEditorHeader name={name} onChange={val => onChange({ name: val })} />} */}

      {editable ? (
        <div className="question-info-select">
          <input
            ref={titleRef}
            className="qst-title-input"
            placeholder="Write here your question title..."
            defaultValue={label}
            onChange={e => onChange({ title: e.target.value })}
          />{' '}
          <QuestionTypeSelector
            name={name}
            type={type}
            onChange={val => onChange({ type: val.type })}
          />
        </div>
      ) : (
        <div
          id={name}
          className={`qst-title${label ? '' : ' placeholder'}${title ? '' : ' empty'}`}
        >
          {label || 'Write here your question title...'}
          {rules?.required?.value === true && (
            <span title="Required" aria-label="Required" className="required-mark">
              *
            </span>
          )}
        </div>
      )}

      {editable && (
        <input
          className="qst-helper-input"
          placeholder="Question footer, note or helper text..."
          defaultValue={typeof helper === 'function' ? helper() : helper}
          onChange={e => onChange({ helper: e.target.value })}
        />
      )}

      {editable && (
        <QuestionOptionsEditor
          name={name}
          options={options}
          cols={cols}
          onChange={updatedVals => onChange(updatedVals)}
        />
      )}

      {!!labelsTop.length && (
        <div className="question-labels top">
          <div className="label left">{labelsTop[0]}</div>
          <div className="label center">{labelsTop[1]}</div>
          <div className="label right">{labelsTop[2]}</div>
        </div>
      )}

      {!editable && parsedOptions && (
        <Controller
          control={control}
          name={name}
          options={options}
          rules={rules}
          defaultValue={value || null}
          render={
            ({ field }) => (
              <ButtonsGroup
                cols={cols}
                aria-labelledby={name}
                errorState={error}
                isStackableOnMobile={isStackableOnMobile}
              >
                {parsedOptions.map((opt, i) => {
                  const focusProps = i === 0 ? field : {};
                  const isChecked = field.value === opt.value;
                  return (
                    <button
                      // key={opt.value}
                      key={opt.id}
                      type="button"
                      data-invalid={error ? 'true' : 'false'}
                      {...focusProps}
                      className="radio-option"
                      data-checked={isChecked}
                      onClick={() => field.onChange(opt.value)}
                    >
                      <span className={`radio-icon${isChecked ? ' checked' : ''}`}>
                        <Icon size={24} icon={isChecked ? iconChecked : iconUnchecked} />
                      </span>
                      <div className="radio-label">{opt.label || opt.value}</div>
                    </button>
                  );
                })}
              </ButtonsGroup>
            ) // props contains: onChange, onBlur and value
          }
        />
      )}
      {!!labelsBottom.length && (
        <div className="question-labels bottom">
          <div className="label left">{labelsBottom[0]}</div>
          <div className="label center">{labelsBottom[1]}</div>
          <div className="label right">{labelsBottom[2]}</div>
        </div>
      )}

      {!editable && helper && (
        <span className="form-text question-helper">
          {typeof helper === 'function' ? helper() : helper}
        </span>
      )}

      <QuestionError error={error} />

      {/**
            {editable && (
        <div>
          <pre>
            {JSON.stringify(
              { name, type, label, options, cols, rules, labelsTop, labelsBottom, helper },
              (key, keyVal) => (typeof keyVal === 'undefined' ? null : keyVal),
              2
            )}
          </pre>
        </div>
      )}
       */}

      {editable && (
        <QuestionSettingsDrawer
          question={props}
          name={name}
          type={type}
          validation={rules}
          onChange={validation => onChange({ validation })}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
        />
      )}
    </RadioButtonsWrapper>
  );
};

export default RadioButtons;
