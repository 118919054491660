/* eslint-disable react/display-name */
import dynamic from 'next/dynamic';
import {
  parseTextVariables,
  parseFunction,
  parsedHiddenValue,
  getDefaultQuestionError
} from '@/utils/renderText';
import { hiddenResponsesParser } from '@/utils/parser';
import { cleanObject } from '@/utils/index';
import Input from './Input';
import Textarea from './Textarea';
import Sentiment from './Sentiment';
import Buttons from './Buttons';
import Matrix from './Matrix';
// import Radio from './Radio';
import Radio from './RadioButtons';
import { validationRules } from './validation';

// import Marked from '@/components/Marked';
// import MDText from './MDText';
// import CheckboxGroup from './Checkbox';
// import Slider from './Slider';
// import Select from './Select';
// import DateQuestion from './Date';
// import Rating from './Rating';
const Marked = dynamic(() => import('@/components/Marked'));
const MDText = dynamic(() => import('./MDText'));
const CheckboxGroup = dynamic(() => import('./Checkbox'));
const Slider = dynamic(() => import('./Slider'));
const Select = dynamic(() => import('./Select'));
const Hidden = dynamic(() => import('./Hidden'));
const Rating = dynamic(() => import('./Rating'), {
  ssr: false
});
const DateQuestion = dynamic(() => import('./Date'), {
  ssr: false
});

export { default as QuestionEditor } from './QuestionEditor';

const QuestionRenderer = ({
  questions,
  control,
  errors,
  responses,
  stageValues = {},
  disabled,
  defaultValues,
  lang
}) => {
  // get stage values from useForm props
  const defaultQuestionValues = questions
    .map(qst => {
      if (qst.defaultValue) {
        return { [qst.name]: qst.defaultValue };
      }
      if (qst.hiddenValue) {
        // console.log('must compute hidden');
        // console.log({ qst });
        const hiddenResponse = hiddenResponsesParser({
          questions: [qst],
          data: {
            ...cleanObject(defaultValues),
            ...cleanObject(responses),
            ...cleanObject(stageValues)
          }
        });
        // console.log({ hiddenResponse });
        return hiddenResponse;
      }
      return null;
    })
    .filter(qst => qst)
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  // console.log({ stageValues });
  // console.log({ defaultQuestionValues, defaultValues, responses, stageValues });
  // merge initial defaultQuestionValues, past stage responses with current stage values

  const startValues = {
    ...cleanObject(defaultQuestionValues),
    ...cleanObject(stageValues),
    ...cleanObject(defaultValues),
    ...cleanObject(responses)
  };

  const hiddenResponses = hiddenResponsesParser({
    questions,
    data: { ...cleanObject(startValues), ...cleanObject(stageValues) }
  });

  // console.log({
  //   defaultQuestionValues,
  //   stageValues,
  //   defaultValues,
  //   responses,
  //   hiddenResponses
  // });
  const values = {
    ...cleanObject(startValues),
    ...cleanObject(hiddenResponses)
  };
  // console.log({ values });

  const parseErrorText = ({ error, validation }) => {
    if (!error) {
      return error;
    }
    const type = error?.type;
    if (!type) {
      return error;
    }
    const defaultMessage = getDefaultQuestionError(type, validation, lang); // lang?
    // console.log({ defaultMessage });
    const message = error.message || defaultMessage;
    // errror object = {
    //   message: undefined,
    //   ref: {name: "sentiment-base", focus: ƒ},
    //   type: "require",
    // }
    // cases:
    // required, min, max, minLength, maxLength, pattern, validate
    // https://react-hook-form.com/api#register

    // console.log({ error, validation });
    return { ...error, message };
  };

  return questions.map(qst => {
    const {
      type,
      name,
      title,
      helper,
      options = [],
      validation = {},
      ratingMax = 5,
      step = 1,
      markers = [],
      checkAll = false,
      defaultValue,
      isVisible,
      hiddenValue,
      ...restProps
    } = qst;
    // check current question visible fn, otherwise return true
    const isCurrentQuestionVisible = isVisible
      ? parseFunction(isVisible, values, '0')(values)
      : true;

    const parsedLabel =
      typeof title === 'function'
        ? title({})
        : parseTextVariables({ text: title, variables: values });

    const questionValue = () => {
      // if hidden value, parse hidden string
      if (hiddenValue) {
        const computedHiddenValue =
          hiddenValue && typeof hiddenValue === 'function'
            ? hiddenValue({
                ...cleanObject(values),
                ...cleanObject(stageValues)
              })
            : parsedHiddenValue({
                text: hiddenValue,
                variables: {
                  ...cleanObject(values),
                  ...cleanObject(stageValues)
                }
              });
        return computedHiddenValue;
      }
      // if not hidden, return initial value
      return values[name];
    };
    const commonProps = {
      key: name,
      name,
      label: <Marked>{parsedLabel || ''}</Marked>,
      helper: <Marked>{helper || ''}</Marked>,
      title,
      control,
      rules: validationRules(validation),
      value: questionValue() || defaultValue,
      error: parseErrorText({
        error: errors[name],
        validation: validationRules(validation)
      }),
      isVisible: isCurrentQuestionVisible,
      disabled
    };
    const questionProps = {
      ...restProps,
      ...commonProps
    };

    if (type === 'mdtext') {
      return <MDText {...questionProps} />;
    }
    if (type === 'input') {
      return <Input {...questionProps} />;
    }
    if (type === 'textarea') {
      return <Textarea {...questionProps} />;
    }
    if (type === 'date') {
      return <DateQuestion {...questionProps} />;
    }
    if (type === 'dropdown') {
      return <Select {...questionProps} options={options} />;
    }
    if (type === 'rating') {
      return <Rating {...questionProps} ratingMax={ratingMax} />;
    }
    if (type === 'slider') {
      return (
        <Slider
          // pass slider options as min max
          {...questionProps}
          step={step}
          markers={markers}
        />
      );
    }
    if (type === 'radio') {
      return <Radio {...questionProps} options={options} />;
    }
    if (type === 'checkbox') {
      return <CheckboxGroup {...questionProps} options={options} checkAll={checkAll} />;
    }
    if (type === 'sentiment') {
      return <Sentiment {...questionProps} />;
    }
    if (type === 'buttons') {
      return <Buttons {...questionProps} options={options} />;
    }
    if (type === 'matrix') {
      return <Matrix {...questionProps} options={options} />;
    }
    if (type === 'hidden') {
      return (
        <Hidden
          {...questionProps}
          // options={options}
          // value={computedHiddenValue}
          values={{ ...cleanObject(values) }}
          liveView
        />
      );
    }
    return <div />;
  });
};

export default QuestionRenderer;

export {
  MDText,
  Input,
  Textarea,
  CheckboxGroup,
  Radio,
  Rating,
  Select,
  Slider,
  Sentiment,
  Buttons,
  Hidden
};
