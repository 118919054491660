import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import dynamic from 'next/dynamic';
import { Controller } from 'react-hook-form';

import { animated } from 'react-spring';
import { Icon } from 'react-icons-kit';
import { star } from 'react-icons-kit/fa/star';
import { heart } from 'react-icons-kit/fa/heart';
import { helpCircle } from 'react-icons-kit/feather/helpCircle';
import useBoop from '@/hooks/use-boop.hook';
import { RatingWrapper, RatingGroup, CheckboxWrapper } from '@/styles/questions.styles';

import QuestionTypeSelector from './Shared/QuestionTypeSelector';
import QuestionSettingsDrawer from './Shared/QuestionSettingsDrawer';
// import QuestionEditorHeader from './Shared/QuestionEditorHeader';
import QuestionError from './Shared/QuestionError';

import { Checkbox } from './Checkbox';
// import Tippy from '@/components/Tooltip';
const Tippy = dynamic(() => import('@/components/Tooltip'), { ssr: false });

// This helper function is used in the component
const normalize = (number, currentScaleMin, currentScaleMax, newScaleMin = 0, newScaleMax = 1) => {
  // FIrst, normalize the value between 0 and 1.
  const standardNormalization = (number - currentScaleMin) / (currentScaleMax - currentScaleMin);
  // Next, transpose that value to our desired scale.
  return (newScaleMax - newScaleMin) * standardNormalization + newScaleMin;
};

const useAngledBoop = (index, startingAngle = 90) => {
  // Our star has 5 points across a 360-degree area.
  // Our first point should shoot out at 0 degrees,
  // our second at 72 degrees (1/5th of 360),
  // our third at 144 degrees, and so on.
  let angle = index * (360 / 5);
  // By default in JS, 0-degrees is the 3-o'clock
  // position, but I want my animation to start at
  // the 12-o'clock position, so I'll subtract
  // 90 degrees
  angle -= startingAngle;
  // Trigonometry methods in JS use radians, not
  // degrees, so we need to convert.
  const angleInRads = (angle * Math.PI) / 180;
  // If this was meant to be reusable, this would
  // be configurable, but it's not, so it's
  // hardcoded. The # of pixels from the center
  // that our circle will bounce.
  const distance = 32; // 42
  // Convert polar coordinages (angle, distance)
  // to cartesian ones (x, y), since JS uses
  // a cartesian coordinate system:
  const x = distance * Math.cos(angleInRads);
  const y = distance * Math.sin(angleInRads);
  // `normalize` is commonly called "lerp",
  // as well as Linear Interpolation. It
  // maps a value from one scale to another.
  // In this case, I want the time to vary
  // between 450ms and 600ms, with the first
  // point being the slowest, and the last
  // one being the fastest.
  //
  // It's defined below
  let timing = normalize(index, 0, 4, 450, 600);
  // `normalize` produces linear interpolation,
  // but I want there to be a *bit* of an ease;
  // I want it to appear to be slowing down,
  // as we get further into the circles.
  timing *= 1 + index * 0.22;
  const friction = normalize(index, 0, 4, 15, 40); // ... 15, 40
  const boop = useBoop({
    x,
    y,
    timing,
    scale: 1.4,
    springConfig: { tension: 180, friction }
  });
  return boop;
};

const getIconSize = n => {
  // if more than 7
  if (n > 7) {
    return 36;
  }
  return 48;
};

const VARIANTS = ['star', 'heart'];

const Rating = props => {
  const {
    type,
    name,
    label,
    title,
    showValues = true,
    ratingMax = 5,
    control,
    rules = {},
    variant = 'star',
    helper,
    error,
    value,
    labelsTop = [],
    isVisible = true,
    disabled,
    editable,
    onChange,
    onDuplicate,
    onDelete
  } = props;

  // handle automatic title input focus on selected question
  const titleRef = useRef();
  useEffect(() => {
    if (editable) {
      if (titleRef.current) {
        titleRef.current.focus();
      }
    }
  }, [editable]);

  const variantIsAllowed = VARIANTS.includes(variant);

  // console.log({ ratingMax });
  const range = ratingMax < 3 ? 3 : ratingMax > 10 ? 10 : ratingMax;
  // hardcode use range from min 3 to 10 max
  const ratings = [...Array(parseInt(range))];
  const [hover, setHover] = useState(null);
  const iconSize = getIconSize(range);
  const isStackableOnMobile = range > 5;
  if (!isVisible) {
    return null;
  }

  return (
    <RatingWrapper className="star-rating-question" errorState={error}>
      {/* {editable && <QuestionEditorHeader name={name} onChange={val => onChange({ name: val })} />} */}

      {editable ? (
        <div className="question-info-select">
          <input
            ref={titleRef}
            className="qst-title-input"
            placeholder="Write here your question title..."
            defaultValue={label}
            onChange={e => onChange({ title: e.target.value })}
          />{' '}
          <QuestionTypeSelector
            name={name}
            type={type}
            onChange={val => onChange({ type: val.type })}
          />
        </div>
      ) : (
        <div
          id={name}
          className={`qst-title${label ? '' : ' placeholder'}${title ? '' : ' empty'}`}
        >
          {label || 'Write here your question title...'}
          {rules?.required?.value === true && (
            <span title="Required" aria-label="Required" className="required-mark">
              *
            </span>
          )}
        </div>
      )}

      {editable && (
        <input
          className="qst-helper-input"
          placeholder="Question footer, note or helper text..."
          defaultValue={typeof helper === 'function' ? helper() : helper}
          onChange={e => onChange({ helper: e.target.value })}
        />
      )}

      {editable && (
        <VariantSelect
          name={name}
          variant={variantIsAllowed ? variant : 'star'}
          ratingMax={ratingMax}
          onChange={val => onChange({ variant: val })}
          onRangeChange={val => onChange({ ratingMax: val })}
        />
      )}

      {!!labelsTop.length && (
        <div className="question-labels top">
          <div className="label left">{labelsTop[0]}</div>
          <div className="label center">{labelsTop[1]}</div>
          <div className="label right">{labelsTop[2]}</div>
        </div>
      )}

      {!editable && (
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={value || null}
          render={({ field }) => (
            <>
              <RatingGroup
                className="star-rating"
                aria-labelledby={name}
                errorState={error}
                isStackableOnMobile={isStackableOnMobile}
              >
                {ratings.map((opt, i) => {
                  const ratingValue = i + 1;
                  const key = `${name}_${ratingValue}`;
                  const focusProps = i === 0 ? field : {};
                  return (
                    <RatingButton
                      key={key}
                      data-invalid={error ? 'true' : 'false'}
                      size={iconSize}
                      variant={variantIsAllowed ? variant : 'star'}
                      value={ratingValue}
                      itemProps={field}
                      focusProps={focusProps}
                      // pass parent state to buttons
                      hover={hover}
                      setHover={setHover}
                      disabled={disabled}
                    />
                  );
                })}
              </RatingGroup>
              {showValues && (
                <div className="question-labels bottom">
                  <div className="label left" />
                  <div className="label center">
                    {field.value || 0} / {ratings.length}
                  </div>
                  <div className="label right" />
                </div>
              )}
            </>
          )} // props contains: onChange, onBlur and value
        />
      )}

      {!editable && helper && (
        <span className="form-text question-helper">
          {typeof helper === 'function' ? helper() : helper}
        </span>
      )}
      <QuestionError error={error} />

      {/**
            {editable && (
        <div>
          <pre>
            {JSON.stringify(
              { name, type, label, variant, ratingMax, showValues, rules, labelsTop, helper },
              (key, keyVal) => (typeof keyVal === 'undefined' ? null : keyVal),
              2
            )}
          </pre>
        </div>
      )}
       */}

      {editable && (
        <QuestionSettingsDrawer
          question={props}
          name={name}
          type={type}
          validation={rules}
          onChange={validation => onChange({ validation })}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
        />
      )}
    </RatingWrapper>
  );
};

export default Rating;

const VariantSelect = ({ name, variant, ratingMax, onChange, onRangeChange }) => {
  const RANGE_OPTIONS = [3, 5, 7, 10].map(opt => ({ value: opt, label: opt }));
  const selectedRange = RANGE_OPTIONS.find(range => range.value === ratingMax);
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div>
      <div className="options-header">
        <div className="options-label">
          <div>Options</div>

          <div className="range-label">
            Range{' '}
            <span className="help">
              <Tippy
                content={
                  <div className="help-tooltip">
                    <div>Number of total range items</div>
                  </div>
                }
                visible={showTooltip}
                onClickOutside={() => setShowTooltip(false)}
              >
                <button type="button" onClick={() => setShowTooltip(!showTooltip)}>
                  <Icon size={12} icon={helpCircle} />
                </button>
              </Tippy>
            </span>
          </div>

          <div className="range-number-wrapper">
            <Select
              id={`${name}_range`}
              instanceId={`${name}_range`}
              className="range-dropdown"
              classNamePrefix="range-dropdown"
              options={RANGE_OPTIONS}
              // isDisabled={state.length === 0}
              value={selectedRange || { value: 5, label: 5 }}
              isSearchable={false}
              onChange={option => onRangeChange(option.value)}
            />
          </div>
        </div>
        <div className="options-desc">
          Select range variant:{' '}
          {/* <span className="help">
            <Tippy
              content={
                <div className="help-tooltip">
                  <div>
                    "Simple" will show a 3 options range (values 1-3) <br />
                    "Complete" will show a 5 options range (values 1-5)
                  </div>
                </div>
              }
              visible={showTooltip}
              onClickOutside={() => setShowTooltip(false)}
            >
              <button type="button" onClick={() => setShowTooltip(!showTooltip)}>
                <Icon size={12} icon={helpCircle} />
              </button>
            </Tippy>
          </span> */}
        </div>
      </div>
      <div className="variant-opt">
        <CheckboxWrapper className="variant-check-wrapper">
          <label>
            <Checkbox
              id={`${name}-variant-stars`}
              name={`${name}-variant`}
              value="star"
              checked={variant === 'star'}
              onChange={() => onChange('star')}
            />
            <div className="checkbox-label">
              Stars <Icon size={18} icon={star} className="star-item" />
            </div>
          </label>
          <label>
            <Checkbox
              id={`${name}-variant-hearts`}
              name={`${name}-variant`}
              value="heart"
              checked={variant === 'heart'}
              onChange={() => onChange('heart')}
            />
            <div className="checkbox-label">
              Hearts <Icon size={18} icon={heart} className="heart-item" />
            </div>
          </label>
        </CheckboxWrapper>
      </div>
    </div>
  );
};

const RatingButton = ({
  value,
  size,
  variant,
  hover,
  setHover,
  itemProps,
  focusProps,
  disabled
}) => {
  // animation props
  // const [c1s, c1t] = useAngledBoop(0);
  // const [c2s, c2t] = useAngledBoop(1);
  // const [c3s, c3t] = useAngledBoop(2);
  // const [c4s, c4t] = useAngledBoop(3);
  // const [c5s, c5t] = useAngledBoop(4);
  const [c6s, c6t] = useAngledBoop(0, 45);
  const [c7s, c7t] = useAngledBoop(1, 45);
  const [c8s, c8t] = useAngledBoop(2, 45);
  const [c9s, c9t] = useAngledBoop(3, 45);
  const [c10s, c10t] = useAngledBoop(4, 45);
  const [animationStyles, animationTrigger] = useBoop({
    scale: 1.1, // 1.1
    rotation: 10,
    timing: 150,
    springConfig: {
      tension: 300,
      friction: 6
    }
  });

  const callAnimation = () => {
    // c1t();
    // c2t();
    // c3t();
    // c4t();
    // c5t();
    c6t();
    c7t();
    c8t();
    c9t();
    c10t();
    animationTrigger();
  };

  return (
    <ButtonWrapper>
      <button
        {...focusProps}
        type="button"
        className="rating-option"
        data-checked={value <= (hover || itemProps.value)}
        aria-disabled={disabled}
        disabled={disabled}
        onClick={() => {
          itemProps.onChange(value);
          // If I had more than 5 points, I might
          // write a `callAll()` helper function.
          // But I don't, so this is fine.
          callAnimation();
        }}
        onMouseEnter={() => setHover(value)}
        onMouseLeave={() => setHover(null)}
      >
        <IconWrapper style={animationStyles}>
          <Icon
            size={size}
            icon={variant === 'star' ? star : heart}
            className={`rating-item-${variant}`}
            checked={value <= (hover || itemProps.value)}
            aria-disabled={disabled}
            aria-checked={value <= (hover || itemProps.value)}
            data-focused={hover && value === (hover || itemProps.value)}
          />
        </IconWrapper>
      </button>
      {/* <Particle style={c1s}>
        <Icon size={size / 4} icon={star} className="stars-item" />
      </Particle>
      <Particle style={c2s}>
        <Icon size={size / 4} icon={star} className="stars-item" />
      </Particle>
      <Particle style={c3s}>
        <Icon size={size / 4} icon={star} className="stars-item" />
      </Particle>
      <Particle style={c4s}>
        <Icon size={size / 4} icon={star} className="stars-item" />
      </Particle>
      <Particle style={c5s}>
        <Icon size={size / 4} icon={star} className="stars-item" />
      </Particle> */}
      <Circle style={c6s} />
      <Circle style={c7s} />
      <Circle style={c8s} />
      <Circle style={c9s} />
      <Circle style={c10s} />
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  position: relative;
  width: min-content;
  z-index: 0;
  button {
    position: relative;
    z-index: 3;
    padding: 4px;
    border-radius: 50%;
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    button {
      padding: 2px;
    }
  }
`;
const IconWrapper = styled(animated.span)`
  display: block;
`;
// const Particle = styled(animated.div)`
//   position: absolute;
//   z-index: 1;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   margin: auto;
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   .stars-item {
//     color: var(--yellow);
//   }
// `;
const Circle = styled(animated.div)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 4px;
  height: 4px;
  margin: auto;
  border-radius: 50%;
  background: var(--red);
`;
