import { useEffect, useRef } from 'react';
import Markdown from 'markdown-to-jsx';
// import { Controller } from 'react-hook-form';
import { MDQuestionWrapper } from '@/styles/questions.styles';
import QuestionTypeSelector from './Shared/QuestionTypeSelector';
import QuestionSettingsDrawer from './Shared/QuestionSettingsDrawer';
// import QuestionEditorHeader from './Shared/QuestionEditorHeader';
// import QuestionValidationOptions from './Shared/QuestionValidationOptions';
import QuestionError from './Shared/QuestionError';

const MarkdownSpanOverride = ({ children }) => <span>{children}</span>;
const MarkdownLinkOverride = ({ children, href }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);
/* eslint-disable jsx-a11y/alt-text */
const MarkdownImgOverride = props => (
  <span className="img-wrapper">
    <img {...props} />
  </span>
);
// only allow emphasis https://github.com/probablyup/markdown-to-jsx/blob/master/fixture.md
const Marked = ({ children }) => (
  <Markdown
    options={{
      disableParsingRawHTML: true, // disable parsing html tags https://github.com/probablyup/markdown-to-jsx#optionsdisableparsingrawhtml
      overrides: {
        // h1: MarkdownSpanOverride,
        // h2: MarkdownSpanOverride,
        // h3: MarkdownSpanOverride,
        // h4: MarkdownSpanOverride,
        h5: MarkdownSpanOverride,
        h6: MarkdownSpanOverride,
        // blockquote: MarkdownSpanOverride,
        // ul: MarkdownSpanOverride,
        // ol: MarkdownSpanOverride,
        // li: MarkdownSpanOverride,
        code: MarkdownSpanOverride,
        pre: MarkdownSpanOverride,
        a: MarkdownLinkOverride,
        img: MarkdownImgOverride
        // hr: MarkdownSpanOverride,
        // br: MarkdownSpanOverride
      }
    }}
  >
    {children}
  </Markdown>
);

const MDText = props => {
  const {
    type,
    name,
    label,
    title,
    // control,
    rules = {},
    helper,
    content,
    error,
    // value,
    isVisible = true,
    editable,
    onChange,
    onDuplicate,
    onDelete
    // ...restProps
  } = props;

  // handle automatic title input focus on selected question
  const titleRef = useRef();
  useEffect(() => {
    if (editable) {
      if (titleRef.current) {
        titleRef.current.focus();
      }
    }
  }, [editable]);

  if (!isVisible) {
    return null;
  }
  // if (editable) {
  //   console.log({ props });
  // }

  // const handleChange = () => {};

  return (
    <MDQuestionWrapper className="input-question" errorState={error}>
      {/* {editable && <QuestionEditorHeader name={name} onChange={val => onChange({ name: val })} />} */}

      {editable ? (
        <div className="question-info-select">
          <input
            ref={titleRef}
            className="qst-title-input"
            placeholder="Write here your question title..."
            defaultValue={label}
            onChange={e => onChange({ title: e.target.value })}
          />{' '}
          <QuestionTypeSelector
            name={name}
            type={type}
            onChange={val => onChange({ type: val.type })}
          />
        </div>
      ) : (
        <div className={`qst-title${label ? '' : ' placeholder'}${title ? '' : ' empty'}`}>
          {label || 'Write here your question title...'}
          {rules?.required?.value === true && (
            <span title="Required" aria-label="Required" className="required-mark">
              *
            </span>
          )}
        </div>
      )}

      {editable && (
        <textarea
          className="qst-md-input"
          placeholder="Write some text..."
          defaultValue={typeof content === 'function' ? content() : content}
          onChange={e => onChange({ content: e.target.value })}
        />
      )}

      {editable && (
        <input
          className="qst-helper-input"
          placeholder="Question footer, note or helper text..."
          defaultValue={typeof helper === 'function' ? helper() : helper}
          onChange={e => onChange({ helper: e.target.value })}
        />
      )}

      {!editable && (
        <div className="md-text-wrapper">
          <Marked>{content || ''}</Marked>
        </div>
        // <label htmlFor={name} aria-label={title}>
        //   <Controller
        //     control={control}
        //     name={name}
        //     rules={rules}
        //     defaultValue={value || ''}
        //     render={({ field }) => (
        //       <textarea
        //         id={name}
        //         name={name}
        //         data-invalid={error ? 'true' : 'false'}
        //         {...field}
        //         {...restProps}
        //       />
        //     )} // props contains: onChange, onBlur and value
        //   />
        // </label>
      )}

      {!editable && helper && (
        <span className="form-text question-helper">
          {typeof helper === 'function' ? helper() : helper}
        </span>
      )}

      <QuestionError error={error} />

      {/**

            {editable && (
        <div>
          <pre>
            {JSON.stringify(
              { name, type, label, rules, helper },
              (key, keyVal) => (typeof keyVal === 'undefined' ? null : keyVal),
              2
            )}
          </pre>
        </div>
      )}
       */}

      {editable && (
        <QuestionSettingsDrawer
          question={props}
          name={name}
          type={type}
          validation={rules}
          onChange={validation => onChange({ validation })}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
          isPlainText
        />
      )}
      {/* {editable && (
        <QuestionValidationOptions name={name} type={type} onChange={val => console.log(val)} />
      )} */}
    </MDQuestionWrapper>
  );
};

export default MDText;
