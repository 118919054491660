import { useEffect, useRef } from 'react';
// import Select from 'react-select';
import { Controller } from 'react-hook-form';
import { InputWrapper } from '@/styles/questions.styles';
// import { generateOptions } from '@/utils/index';
import QuestionTypeSelector from './Shared/QuestionTypeSelector';
import QuestionSettingsDrawer from './Shared/QuestionSettingsDrawer';
// import QuestionEditorHeader from './Shared/QuestionEditorHeader';
// import QuestionOptionsEditor from './Shared/QuestionOptionsEditor';
import QuestionError from './Shared/QuestionError';

const HiddenInput = props => {
  const {
    type,
    name,
    label,
    title,
    // options,
    control,
    rules = {},
    // helper,
    error,
    value,
    hiddenValue,
    isVisible = true,
    // disabled,
    editable,
    onChange,
    onDuplicate,
    onDelete,
    liveView
    // values
  } = props;
  // console.log({ props });

  // handle automatic title input focus on selected question
  const titleRef = useRef();
  useEffect(() => {
    if (editable) {
      if (titleRef.current) {
        titleRef.current.focus();
      }
    }
  }, [editable]);

  if (!isVisible) {
    return null;
  }

  return (
    <InputWrapper
      className="input-question"
      style={liveView ? { display: 'none' } : { display: 'inherit' }}
      errorState={error}
    >
      {/* {editable && <QuestionEditorHeader name={name} onChange={val => onChange({ name: val })} />} */}
      {editable ? (
        <div className="question-info-select">
          <input
            ref={titleRef}
            className="qst-title-input"
            placeholder="Write here your question title..."
            defaultValue={label}
            onChange={e => onChange({ title: e.target.value })}
          />{' '}
          <QuestionTypeSelector
            name={name}
            type={type}
            onChange={val => onChange({ type: val.type })}
          />
        </div>
      ) : (
        <div className={`qst-title${label ? '' : ' placeholder'}${title ? '' : ' empty'}`}>
          {label || 'Write here your question title...'}
          {!liveView && (
            <span title="Hidden" aria-label="Hidden variable" className="upper-label-info">
              Hidden variable
            </span>
          )}
          {rules?.required?.value === true && (
            <span title="Required" aria-label="Required" className="required-mark">
              *
            </span>
          )}
        </div>
      )}
      {/* {editable && (
        <input
          className="qst-helper-input"
          placeholder="Question footer, note or helper text..."
          defaultValue={typeof helper === 'function' ? helper() : helper}
          onChange={e => onChange({ helper: e.target.value })}
        />
      )} */}

      {editable && (
        <input
          className="qst-hidden-input"
          placeholder="Hidden value"
          defaultValue={typeof hiddenValue === 'function' ? hiddenValue() : hiddenValue}
          onChange={e => onChange({ hiddenValue: e.target.value })}
        />
      )}

      {!editable && (
        <label htmlFor={name} aria-label={title}>
          <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={value || null}
            render={({ field }) => (
              <div
                {...field}
                id={name}
                // instanceId={name}
                // data-invalid={error ? 'true' : 'false'}
                // // inputId={name}
                // className="select-dropdown"
                // classNamePrefix="select-dropdown"
                // options={selectOptions}
                // isDisabled={disabled}
              />
            )} // props contains: onChange, onBlur and value
          />
        </label>
      )}
      {/* {!editable && helper && (
        <span className="form-text question-helper">
          {typeof helper === 'function' ? helper() : helper}
        </span>
      )} */}
      <QuestionError error={error} />
      {/**
            {editable && (
        <div>
          <pre>
            {JSON.stringify(
              { name, type, label, options, rules, helper },
              (key, keyVal) => (typeof keyVal === 'undefined' ? null : keyVal),
              2
            )}
          </pre>
        </div>
      )}
       */}
      {editable && (
        <QuestionSettingsDrawer
          question={props}
          name={name}
          type={type}
          validation={rules}
          onChange={validation => onChange({ validation })}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
        />
      )}
    </InputWrapper>
  );
};

export default HiddenInput;
