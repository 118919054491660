export const allRowsRequired = rows => rows.every(dp => dp.response);

export const validationRules = rules => {
  const cloned = { ...rules };
  const validate = cloned.validate ? { ...cloned.validate } : {};
  // if all rows required
  if (cloned.allRowsRequired && cloned.allRowsRequired?.value) {
    validate.allRowsRequired = v => allRowsRequired(v);
  }
  const parsedRules = { ...cloned, validate };
  // console.log({ parsedRules });
  return parsedRules;
};

// validate.allRowsRequired = v =>
//   allRowsRequired(v) || 'Por favor responde todas las filas';
