import { useState, useEffect, useRef, forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { Icon } from 'react-icons-kit';
import { calendar } from 'react-icons-kit/entypo/calendar';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';

import { DateWrapper } from '@/styles/questions.styles';
import QuestionTypeSelector from './Shared/QuestionTypeSelector';
import QuestionSettingsDrawer from './Shared/QuestionSettingsDrawer';
// import QuestionEditorHeader from './Shared/QuestionEditorHeader';
import QuestionError from './Shared/QuestionError';

import 'react-datepicker/dist/react-datepicker.css';
// register available locales
registerLocale('es', es);

const DateComponent = props => {
  const {
    type,
    name,
    label,
    title,
    control,
    rules = {},
    helper,
    error,
    value,
    isVisible = true,
    disabled,
    editable,
    onChange,
    onDuplicate,
    onDelete,
    ...restProps
  } = props;
  // console.log({ error });
  // console.log({restProps})

  // handle automatic title input focus on selected question
  const titleRef = useRef();
  useEffect(() => {
    if (editable) {
      if (titleRef.current) {
        titleRef.current.focus();
      }
    }
  }, [editable]);

  if (!isVisible) {
    return null;
  }

  return (
    <DateWrapper className="date-question" errorState={error}>
      {/* {editable && <QuestionEditorHeader name={name} onChange={val => onChange({ name: val })} />} */}

      {editable ? (
        <div className="question-info-select">
          <input
            ref={titleRef}
            className="qst-title-input"
            placeholder="Write here your question title..."
            defaultValue={label}
            onChange={e => onChange({ title: e.target.value })}
          />{' '}
          <QuestionTypeSelector
            name={name}
            type={type}
            onChange={val => onChange({ type: val.type })}
          />
        </div>
      ) : (
        <div
          id={name}
          className={`qst-title${label ? '' : ' placeholder'}${title ? '' : ' empty'}`}
        >
          {label || 'Write here your question title...'}
          {rules?.required?.value === true && (
            <span title="Required" aria-label="Required" className="required-mark">
              *
            </span>
          )}
        </div>
      )}

      {editable && (
        <input
          className="qst-helper-input"
          placeholder="Question footer, note or helper text..."
          defaultValue={typeof helper === 'function' ? helper() : helper}
          onChange={e => onChange({ helper: e.target.value })}
        />
      )}

      {!editable && (
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={value || ''}
          render={({ field }) => (
            <DateController
              {...props}
              itemRef={field.ref}
              // itemProps={itemProps}
              onChange={val => field.onChange(val)}
              disabled={disabled}
            />
          )} // props contains: onChange, onBlur and value
        />
      )}

      {!editable && helper && (
        <span className="form-text question-helper">
          {typeof helper === 'function' ? helper() : helper}
        </span>
      )}

      <QuestionError error={error} />

      {editable && (
        <div>
          <pre>
            {JSON.stringify(
              {
                name,
                type,
                label,
                rules,
                helper,
                showTimeSelect: props.showTimeSelect,
                timeIntervals: props.timeIntervals,
                locale: props.locale,
                timeCaption: props.timeCaption,
                inline: props.inline
              },
              (key, keyVal) => (typeof keyVal === 'undefined' ? null : keyVal),
              2
            )}
          </pre>
        </div>
      )}

      {editable && (
        <QuestionSettingsDrawer
          question={props}
          name={name}
          type={type}
          validation={rules}
          onChange={validation => onChange({ validation })}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
        />
      )}
    </DateWrapper>
  );
};

const DateController = props => {
  const { name, error, itemRef, onChange, inline = false, disabled, ...restProps } = props;
  const [startDate, setStartDate] = useState(new Date());

  const handleChange = date => {
    setStartDate(date);
    onChange(date);
  };

  const InputButton = forwardRef((btnProps, ref) => {
    const { value, onClick, itemRef } = btnProps;
    return (
      <button
        type="button"
        data-invalid={error ? 'true' : 'false'}
        aria-disabled={disabled}
        disabled={disabled}
        className="input-button"
        ref={itemRef}
        onClick={onClick}
      >
        {value}
      </button>
    );
  });

  const disabledProps = disabled
    ? {
        disabled,
        onChange: () => {}
      }
    : {};
  return (
    <div className={`date-wrapper ${inline ? 'inline' : 'btn'}`} aria-labelledby={name}>
      <DatePicker
        className="date"
        selected={startDate}
        onChange={date => handleChange(date)}
        customInput={<InputButton itemRef={itemRef} />}
        // locale='pt-BR'
        // showTimeSelect
        // timeIntervals={15}
        timeFormat="p"
        dateFormat="Pp"
        inline={inline}
        {...disabledProps}
        {...restProps}
      />
      {!inline && (
        <span className="cal-icon">
          <Icon size="100%" icon={calendar} />
        </span>
      )}
    </div>
  );
};
export default DateComponent;
