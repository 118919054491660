/* eslint-disable camelcase */
import { useState, useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { Icon } from 'react-icons-kit';
import { helpCircle } from 'react-icons-kit/feather/helpCircle';
import { ic_sentiment_very_dissatisfied } from 'react-icons-kit/md/ic_sentiment_very_dissatisfied';
import { ic_sentiment_dissatisfied } from 'react-icons-kit/md/ic_sentiment_dissatisfied';
import { ic_sentiment_neutral } from 'react-icons-kit/md/ic_sentiment_neutral';
import { ic_sentiment_satisfied } from 'react-icons-kit/md/ic_sentiment_satisfied';
import { ic_sentiment_very_satisfied } from 'react-icons-kit/md/ic_sentiment_very_satisfied';
import {
  SentimentWrapper,
  SentimentGroup,
  SentimentItem,
  CheckboxWrapper
} from '@/styles/questions.styles';
import { generateOptions } from '@/utils/index';

import QuestionTypeSelector from './Shared/QuestionTypeSelector';
import QuestionSettingsDrawer from './Shared/QuestionSettingsDrawer';
// import QuestionEditorHeader from './Shared/QuestionEditorHeader';
import QuestionError from './Shared/QuestionError';

import { Checkbox } from './Checkbox';
// import Tippy from '@/components/Tooltip';
const Tippy = dynamic(() => import('@/components/Tooltip'), { ssr: false });

const Sentiment = props => {
  const {
    type,
    name,
    label,
    title,
    // options = [1, 2, 3, 4, 5],
    control,
    rules = {},
    helper,
    error,
    value,
    variant = 'default',
    labelsTop = [],
    labelsBottom = [],
    isVisible = true,
    disabled,
    editable,
    onChange,
    onDuplicate,
    onDelete
  } = props;

  // handle automatic title input focus on selected question
  const titleRef = useRef();
  useEffect(() => {
    if (editable) {
      if (titleRef.current) {
        titleRef.current.focus();
      }
    }
  }, [editable]);

  const sentimentIcons = [
    ic_sentiment_very_dissatisfied,
    ic_sentiment_dissatisfied,
    ic_sentiment_neutral,
    ic_sentiment_satisfied,
    ic_sentiment_very_satisfied
  ];

  const sentimentFacesComplete = [
    { value: 1, src: '/imgs/btn/emoji_1.png' },
    { value: 2, src: '/imgs/btn/emoji_2.png' },
    { value: 3, src: '/imgs/btn/emoji_3.png' },
    { value: 4, src: '/imgs/btn/emoji_4.png' },
    { value: 5, src: '/imgs/btn/emoji_5.png' }
  ];

  const sentimentFacesDefault = [
    { value: 1, src: '/imgs/btn/emoji_2.png' },
    { value: 2, src: '/imgs/btn/emoji_3.png' },
    { value: 3, src: '/imgs/btn/emoji_4.png' }
  ];

  // if (editable) {
  //   console.log({ props });
  // }

  const options = [1, 2, 3, 4, 5]; // hardcoded

  const isComplete = variant === 'complete';

  const completeOptions = generateOptions(options.slice(0, 5));
  const defaultOptions = generateOptions(options.slice(0, 3));
  const sentimentOptions = isComplete ? completeOptions : defaultOptions;
  const sentimentFaces = isComplete ? sentimentFacesComplete : sentimentFacesDefault;
  // console.log({ sentimentOptions });

  if (!isVisible) {
    return null;
  }

  return (
    <SentimentWrapper className="sentiment-question" errorState={error}>
      {/* {editable && <QuestionEditorHeader name={name} onChange={val => onChange({ name: val })} />} */}

      {editable ? (
        <div className="question-info-select">
          <input
            ref={titleRef}
            className="qst-title-input"
            placeholder="Write here your question title..."
            defaultValue={label}
            onChange={e => onChange({ title: e.target.value })}
          />{' '}
          <QuestionTypeSelector
            name={name}
            type={type}
            onChange={val => onChange({ type: val.type })}
          />
        </div>
      ) : (
        <div
          id={name}
          className={`qst-title${label ? '' : ' placeholder'}${title ? '' : ' empty'}`}
        >
          {label || 'Write here your question title...'}
          {rules?.required?.value === true && (
            <span title="Required" aria-label="Required" className="required-mark">
              *
            </span>
          )}
        </div>
      )}

      {editable && (
        <input
          className="qst-helper-input"
          placeholder="Question footer, note or helper text..."
          defaultValue={typeof helper === 'function' ? helper() : helper}
          onChange={e => onChange({ helper: e.target.value })}
        />
      )}

      {editable && (
        <VariantSelect name={name} variant={variant} onChange={val => onChange({ variant: val })} />
      )}

      {!!labelsTop.length && (
        <div className="question-labels top">
          <div className="label left">{labelsTop[0]}</div>
          <div className="label center">{labelsTop[1]}</div>
          <div className="label right">{labelsTop[2]}</div>
        </div>
      )}

      {!editable && options && (
        <Controller
          control={control}
          name={name}
          options={options}
          rules={rules}
          defaultValue={value || null}
          render={({ field }) => (
            <SentimentGroup variant={variant} aria-labelledby={name} errorState={error}>
              {sentimentOptions.map((opt, i) => {
                const focusProps = i === 0 ? field : {};
                // const iconIndex = isComplete ? i : i + 1;
                // const sentimentImg = sentimentFaces.find(sent => sent.value === opt.value);
                // console.log({ sentimentImg });
                const sentimentImg = sentimentFaces[i];
                // console.log({ mut: sentimentFaces[i] });
                return (
                  <SentimentItem
                    // key={opt.value}
                    key={opt.id}
                    type="button"
                    data-invalid={error ? 'true' : 'false'}
                    aria-label={opt.value}
                    {...focusProps}
                    // className={field.value === opt.value ? 'checked' : ''}
                    aria-checked={field.value === opt.value}
                    onClick={() => field.onChange(opt.value)}
                    aria-disabled={disabled}
                    disabled={disabled}
                  >
                    {/**  <Icon size={64} icon={sentimentIcons[iconIndex]} /> */}

                    <div className="emoji-wrapper">
                      <Image
                        src={sentimentImg.src}
                        // alt='pollme mobile ready'
                        alt={`Pollme sentiment ${sentimentImg.value}`}
                        layout="fill"
                      />
                    </div>
                  </SentimentItem>
                );
              })}
            </SentimentGroup>
          )} // props contains: onChange, onBlur and value
        />
      )}

      {!!labelsBottom.length && (
        <div className="question-labels bottom">
          <div className="label left">{labelsBottom[0]}</div>
          <div className="label center">{labelsBottom[1]}</div>
          <div className="label right">{labelsBottom[2]}</div>
        </div>
      )}

      {!editable && helper && (
        <span className="form-text question-helper">
          {typeof helper === 'function' ? helper() : helper}
        </span>
      )}
      <QuestionError error={error} />

      {/**
            {editable && (
        <div>
          <pre>
            {JSON.stringify(
              { name, type, label, variant, rules, labelsTop, labelsBottom, helper },
              (key, keyVal) => (typeof keyVal === 'undefined' ? null : keyVal),
              2
            )}
          </pre>
        </div>
      )}
       */}

      {editable && (
        <QuestionSettingsDrawer
          question={props}
          name={name}
          type={type}
          validation={rules}
          onChange={validation => onChange({ validation })}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
        />
      )}
    </SentimentWrapper>
  );
};

export default Sentiment;

const VariantSelect = ({ name, variant, onChange }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleCheck = newVariant => {
    onChange(newVariant);
  };

  return (
    <div>
      <div className="options-header">
        <div className="options-label">
          <div>Options</div>
        </div>
        <div className="options-desc">
          Select sentiment range{' '}
          <span className="help">
            <Tippy
              content={
                <div className="help-tooltip">
                  <div>
                    "Simple" will show a 3 options range (values 1-3) <br />
                    "Complete" will show a 5 options range (values 1-5)
                  </div>
                </div>
              }
              visible={showTooltip}
              onClickOutside={() => setShowTooltip(false)}
            >
              <button type="button" onClick={() => setShowTooltip(!showTooltip)}>
                <Icon size={12} icon={helpCircle} />
              </button>
            </Tippy>
          </span>
        </div>
      </div>
      <div className="variant-opt">
        <CheckboxWrapper className="variant-check-wrapper">
          <label>
            <Checkbox
              id={`${name}-variant-simple`}
              name={`${name}-variant`}
              value="number"
              checked={variant === 'default'}
              onChange={() => handleCheck('default')}
            />
            <div className="checkbox-label">Simple</div>
          </label>
          <label>
            <Checkbox
              id={`${name}-variant-complete`}
              name={`${name}-variant`}
              value="email"
              checked={variant === 'complete'}
              onChange={() => handleCheck('complete')}
            />
            <div className="checkbox-label">Complete</div>
          </label>
        </CheckboxWrapper>
      </div>
    </div>
  );
};
