import { evalTextFunction } from '@/utils/functions';

const getInnerVariables = text => text.match(/{{(.*?)\}}/g); // regex match values as "{{text}}"

export const parseTextFunction = fnString => {
  // console.log({ fnString });
  const { 1: functionBody } = fnString.split('fn:');
  // eslint-disable-next-line no-eval
  // console.log({ functionBody });
  const parsedFns = evalTextFunction(functionBody);
  // console.log({ parsedFns });
  return parsedFns;
};

export const parseFunctionTextVariables = ({ text = '', variables, fallbackValue }) => {
  // console.log({ text, fallbackValue });
  // use fallbackValue if no variable found
  // console.log({ variables });
  const textVariables = getInnerVariables(text);
  // console.log({ textVariables });
  if (!textVariables) {
    return parseTextFunction(text);
  }
  if (textVariables.length > 0) {
    const parsed = textVariables.reduce((acc, curr) => {
      const fallback = fallbackValue || curr;
      // const variable = curr.replace('{{', '').replace('}}', ''); // get new value somehow
      const name = curr.replace('{{', '').replace('}}', '');
      const variable = variables[name] || fallback; // get new value somehow
      return acc.replace(curr, variable);
    }, text);
    const evaluatedFn = parseTextFunction(parsed);
    // console.log({ evaluatedFn });
    return evaluatedFn;
  }
  return parseTextFunction(text);
};

export const parseTextVariables = ({ text = '', variables, fallbackValue }) => {
  // console.log({ text, fallbackValue });
  // use fallbackValue if no variable found
  // console.log({ variables });
  const textVariables = getInnerVariables(text);
  // console.log({ textVariables });
  if (!textVariables) {
    return text;
  }
  if (textVariables.length > 0) {
    const parsed = textVariables.reduce((acc, curr) => {
      const fallback = fallbackValue || curr;
      // const variable = curr.replace('{{', '').replace('}}', ''); // get new value somehow
      const name = curr.replace('{{', '').replace('}}', '');
      const variable = variables[name] || fallback; // get new value somehow
      // console.log({ variable });
      if (typeof variable === 'string') {
        // is string, parse and clean string
        const isFnVariable = variable.includes('fn:');
        // console.log({ isFnVariable, variable });
        const cleanedVariable = isFnVariable
          ? parseFunctionTextVariables({ text: variable, variables })
          : variable;
        // console.log({ cleanedVariable });
        return acc.replace(curr, cleanedVariable);
      }
      return acc.replace(curr, variable);
    }, text);
    return parsed;
  }
  return text;
};

export const parseFunction = (unparsedFunction, values, fallback) => {
  if (typeof unparsedFunction === 'string') {
    // first, parse text variables
    // console.log({ unparsedFunction });
    // (values) => ({{qst_GVT}} > 12)
    const parsedTextFns = parseTextVariables({
      text: unparsedFunction,
      variables: values,
      fallbackValue: fallback
    });
    // console.log({ parsedTextFns });
    // (values) => (0 > 12)
    const parsedFns = parseTextFunction(parsedTextFns);
    // console.log({ parsedFns });
    return parsedFns;
  }
  return unparsedFunction;
};

export const parsedHiddenValue = ({ text, variables }) => {
  const parsedHiddenString = parseTextVariables({
    text,
    variables
  });
  const isFunctionVariable = parsedHiddenString.includes('fn:');
  // console.log({ isFunctionVariable, parsedHiddenString });
  return isFunctionVariable
    ? parseFunction(parsedHiddenString, variables)(variables)
    : parsedHiddenString;
};

const errorLibrary = {
  en: {
    required: 'This question is required',
    allRowsRequired: 'All rows are required',
    min: 'Min',
    max: 'Max',
    minLength: 'Min length',
    maxLength: 'Max length'
  },
  es: {
    required: 'Esta pregunta es requerida',
    allRowsRequired: 'Debe responder todas las filas',
    min: 'Min',
    max: 'Max',
    minLength: 'Largo min',
    maxLength: 'Largo max'
  }
};
export const getDefaultQuestionError = (type, validation, lang = 'en') => {
  // console.log({ type, validation, lang });
  // cases:
  // required, min, max, minLength, maxLength, pattern, validate
  // https://react-hook-form.com/api#register
  switch (type) {
    case 'required':
      return errorLibrary[lang].required;
    case 'allRowsRequired':
      return errorLibrary[lang].allRowsRequired;
    case 'min':
      return `${errorLibrary[lang].min}: ${validation[type].value || ''}`;
    case 'max':
      return `${errorLibrary[lang].max}: ${validation[type].value || ''}`;
    case 'minLength':
      return `${errorLibrary[lang].minLength}: ${validation[type].value || ''}`;
    case 'maxLength':
      return `${errorLibrary[lang].maxLength}: ${validation[type].value || ''}`;
    default:
      return '';
  }
};
