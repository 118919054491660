/* eslint-disable no-unused-vars */
import { parseTextVariables, parsedHiddenValue } from '@/utils/renderText';
import { evalTextFunction } from '@/utils/functions';

export const hiddenResponsesParser = ({ questions, data }) => {
  const hiddenQuestions = questions.filter(qst => qst.type === 'hidden');
  const hiddenResponses = hiddenQuestions.reduce((acc, curr) => {
    const { hiddenValue, name } = curr;
    const computedHiddenValue =
      hiddenValue && typeof hiddenValue === 'function'
        ? hiddenValue({
            ...data
          })
        : parsedHiddenValue({
            text: hiddenValue,
            variables: {
              ...data
            }
          });
    return { ...acc, [name]: computedHiddenValue };
  }, {});
  return hiddenResponses;
};

export const visiblePageCondition = ({ page, data }) => {
  const { isVisible } = page;
  const computedCondition =
    isVisible && typeof isVisible === 'function'
      ? isVisible({
          ...data
        })
      : parsedHiddenValue({
          text: isVisible,
          variables: {
            ...data
          }
        });
  return computedCondition;
};

export const responseDataParser = data => {
  // console.log({ data });
  const keys = Object.keys(data);

  const parseCheckbox = item => {
    const checkedItems = item.filter(it => it.checked === true);
    return checkedItems.map(it => it.value).join(', ');
  };

  const parseStringVariable = (stringValue, fallbackValue) => {
    const parsedValue = parseTextVariables({
      text: stringValue,
      variables: data,
      fallbackValue
    });
    return parsedValue;
  };

  const parseFunctionVariable = fnString => {
    // console.log(fnString);
    // clean last time for outliers {{variables}}
    const parsedString = parseStringVariable(fnString, '');
    // console.log({ parsedString });
    const { 1: functionBody } = parsedString.split('fn:');
    const parsedFns = evalTextFunction(functionBody);
    // console.log(fnString);
    return parsedFns(data);
  };

  const parseArray = item => {
    // console.log('IS ARRAY');
    // console.log({ item });
    const fields = Object.keys(item[0]);
    // console.log({ fields, item });
    // check if checkbox items
    if (fields.includes('checked')) {
      // const checkedItems = item.filter(it => it.checked === true);
      return parseCheckbox(item);
    }
    // check if matrix items
    if (fields.includes('response')) {
      return item.reduce((acc, curr) => ({ ...acc, [curr.value]: curr.response }), {});
    }
  };

  const parseObject = item =>
    // console.log('IS OBJECT');
    // console.log({ item });
    item?.value || item;

  const parsed = keys.reduce((acc, curr) => {
    const currItem = data[curr];
    // console.log({ data, currItem });
    const isNumberItem = typeof currItem === 'number';
    if (isNumberItem) {
      return { ...acc, [curr]: currItem };
    }
    const isStringItem = typeof currItem === 'string';
    if (isStringItem) {
      const isStringVariable = currItem && currItem.includes('{{');
      const isFunctionVariable = currItem && currItem.startsWith('fn:(v)');
      if (isStringVariable && isFunctionVariable) {
        const parsedItem = parseStringVariable(currItem);
        const currentValue = parseFunctionVariable(parsedItem);
        return { ...acc, [curr]: currentValue };
      }
      if (isStringVariable) {
        const currentValue = parseStringVariable(currItem);
        return { ...acc, [curr]: currentValue };
      }
      if (isFunctionVariable) {
        const currentValue = parseFunctionVariable(currItem);
        return { ...acc, [curr]: currentValue };
      }
    }

    const isArray = Array.isArray(currItem);
    const isObject = typeof currItem === 'object';
    if (isArray) {
      const currentValue = parseArray(currItem);
      // matrix response is object of rows with responses
      const valueIsObject = typeof currentValue === 'object';
      // console.log({ currentValue, valueIsObject });
      if (valueIsObject) {
        // doble check, if is object and has length, spread row responses
        const valueKeys = Object.keys(currentValue);
        if (valueKeys.length > 0) {
          return { ...acc, ...currentValue };
        }
      }
      // otherwise, return as usual
      return { ...acc, [curr]: currentValue };
    }
    if (isObject) {
      const currentValue = parseObject(currItem);
      return { ...acc, [curr]: currentValue };
    }
    // console.log({ isObject, currItem });
    return { ...acc, [curr]: currItem };
  }, {});

  // console.log({ parsed });
  return parsed;
};

export const parseModelQuestionLibrary = model => {
  const OMIT_ELEMENTS = ['mdtext'];
  const groupByQuestion = () => {
    const modelPages = model.pages;
    const elements = modelPages.map(page => {
      const questionElements = page.elements.filter(el => !OMIT_ELEMENTS.includes(el.type));
      return questionElements;
    });
    return elements.flat();
  };
  const questions = groupByQuestion();
  const questionKeys = questions.map(qst => qst.name);
  const questionLibrary = questionKeys.reduce((acc, curr) => {
    const currQuestion = questions.find(qst => qst.name === curr);
    const currHeader = currQuestion?.title || curr;
    return { ...acc, [curr]: currHeader };
  }, {});
  return questionLibrary;
};
