import { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { InputWrapper } from '@/styles/questions.styles';
import QuestionTypeSelector from './Shared/QuestionTypeSelector';
import QuestionSettingsDrawer from './Shared/QuestionSettingsDrawer';
// import QuestionEditorHeader from './Shared/QuestionEditorHeader';
// import QuestionValidationOptions from './Shared/QuestionValidationOptions';
import QuestionError from './Shared/QuestionError';

const Textarea = props => {
  const {
    type,
    name,
    label,
    title,
    control,
    rules = {},
    helper,
    error,
    value,
    isVisible = true,
    editable,
    onChange,
    onDuplicate,
    onDelete,
    inputType,
    ...restProps
  } = props;

  // handle automatic title input focus on selected question
  const titleRef = useRef();
  useEffect(() => {
    if (editable) {
      if (titleRef.current) {
        titleRef.current.focus();
      }
    }
  }, [editable]);

  if (!isVisible) {
    return null;
  }
  // if (editable) {
  //   console.log({ props });
  // }

  // const handleChange = () => {};

  return (
    <InputWrapper className="input-question" errorState={error}>
      {/* {editable && <QuestionEditorHeader name={name} onChange={val => onChange({ name: val })} />} */}

      {editable ? (
        <div className="question-info-select">
          <input
            ref={titleRef}
            className="qst-title-input"
            placeholder="Write here your question title..."
            defaultValue={label}
            onChange={e => onChange({ title: e.target.value })}
          />{' '}
          <QuestionTypeSelector
            name={name}
            type={type}
            onChange={val => onChange({ type: val.type })}
          />
        </div>
      ) : (
        <div className={`qst-title${label ? '' : ' placeholder'}${title ? '' : ' empty'}`}>
          {label || 'Write here your question title...'}
          {rules?.required?.value === true && (
            <span title="Required" aria-label="Required" className="required-mark">
              *
            </span>
          )}
        </div>
      )}

      {editable && (
        <input
          className="qst-helper-input"
          placeholder="Question footer, note or helper text..."
          defaultValue={typeof helper === 'function' ? helper() : helper}
          onChange={e => onChange({ helper: e.target.value })}
        />
      )}

      {!editable && (
        <label htmlFor={name} aria-label={title}>
          <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={value || ''}
            render={({ field }) => (
              <textarea
                id={name}
                name={name}
                data-invalid={error ? 'true' : 'false'}
                {...field}
                {...restProps}
              />
            )} // props contains: onChange, onBlur and value
          />
        </label>
      )}

      {!editable && helper && (
        <span className="form-text question-helper">
          {typeof helper === 'function' ? helper() : helper}
        </span>
      )}
      <QuestionError error={error} />

      {/**

            {editable && (
        <div>
          <pre>
            {JSON.stringify(
              { name, type, label, rules, helper },
              (key, keyVal) => (typeof keyVal === 'undefined' ? null : keyVal),
              2
            )}
          </pre>
        </div>
      )}
       */}

      {editable && (
        <QuestionSettingsDrawer
          question={props}
          name={name}
          type={type}
          validation={rules}
          onChange={validation => onChange({ validation })}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
        />
      )}
      {/* {editable && (
        <QuestionValidationOptions name={name} type={type} onChange={val => console.log(val)} />
      )} */}
    </InputWrapper>
  );
};

export default Textarea;

// const TEXTAREA_QST = {
//   id: 'vg65767',
//   type: 'textarea',
//   name: 'textarea-base',
//   title: 'comments?',
//   validation: {
//     // required: true,
//     required: 'Por favor completa esto',
//     maxLength: {
//       value: 140,
//       message: 'Max 140 caracteres' // JS only: <p>error message</p> TS only support string
//     }
//   },
//   helper: 'Escribe lo que quieras!'
// };
