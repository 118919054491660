import { useState, useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';

// import { Icon } from 'react-icons-kit';
// import { check } from 'react-icons-kit/fa/check';
import { MatrixWrapper, MatrixItems, MatrixOptions, MatrixGroup } from '@/styles/questions.styles';
import { generateOptions, generateRows } from '@/utils/index';
// import QuestionEditorHeader from './Shared/QuestionEditorHeader';
import { Icon } from 'react-icons-kit';
import { ic_radio_button_checked as iconChecked } from 'react-icons-kit/md/ic_radio_button_checked';
import { ic_radio_button_unchecked as iconUnchecked } from 'react-icons-kit/md/ic_radio_button_unchecked';
import QuestionSettingsDrawer from './Shared/QuestionSettingsDrawer';
import QuestionTypeSelector from './Shared/QuestionTypeSelector';
import QuestionError from './Shared/QuestionError';

const Matrix = props => {
  const {
    type,
    name,
    label,
    title,
    options,
    control,
    rules = {},
    helper,
    error,
    value,
    rows,
    labelsTop = [],
    labelsBottom = [],
    isVisible = true,
    editable,
    onChange,
    onDuplicate,
    onDelete
  } = props;
  // console.log({ props });

  // handle automatic title input focus on selected question
  const titleRef = useRef();
  useEffect(() => {
    if (editable) {
      if (titleRef.current) {
        titleRef.current.focus();
      }
    }
  }, [editable]);

  if (!isVisible) {
    return null;
  }

  return (
    <MatrixWrapper className="matrix-question" errorState={error}>
      {/* {editable && <QuestionEditorHeader name={name} onChange={val => onChange({ name: val })} />} */}

      {editable ? (
        <div className="question-info-select">
          <input
            ref={titleRef}
            className="qst-title-input"
            placeholder="Write here your question title..."
            defaultValue={label}
            onChange={e => onChange({ title: e.target.value })}
          />{' '}
          <QuestionTypeSelector
            name={name}
            type={type}
            onChange={val => onChange({ type: val.type })}
          />
        </div>
      ) : (
        <div
          id={name}
          className={`qst-title${label ? '' : ' placeholder'}${title ? '' : ' empty'}`}
        >
          {label || 'Write here your question title...'}
          {rules?.required?.value === true && (
            <span title="Required" aria-label="Required" className="required-mark">
              *
            </span>
          )}
        </div>
      )}

      {editable && (
        <input
          className="qst-helper-input"
          placeholder="Question footer, note or helper text..."
          defaultValue={typeof helper === 'function' ? helper() : helper}
          onChange={e => onChange({ helper: e.target.value })}
        />
      )}

      {!!labelsTop.length && (
        <div className="question-labels top">
          <div className="label left">{labelsTop[0]}</div>
          <div className="label center">{labelsTop[1]}</div>
          <div className="label right">{labelsTop[2]}</div>
        </div>
      )}

      {!editable && (
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={value || null}
          render={({ field, fieldState, formState }) => (
            <MatrixController
              {...props}
              // {...field}
              itemRef={field.ref}
              fieldState={fieldState}
              formState={formState}
              onChange={val => field.onChange(val)}
            />
          )} // props contains: onChange, onBlur and value
        />
      )}

      {!!labelsBottom.length && (
        <div className="question-labels bottom">
          <div className="label left">{labelsBottom[0]}</div>
          <div className="label center">{labelsBottom[1]}</div>
          <div className="label right">{labelsBottom[2]}</div>
        </div>
      )}

      {!editable && helper && (
        <span className="form-text question-helper">
          {typeof helper === 'function' ? helper() : helper}
        </span>
      )}

      <QuestionError error={error} />

      {editable && (
        <div>
          <pre>
            {JSON.stringify(
              {
                name,
                type,
                label,
                options,
                rows,
                rules,
                labelsTop,
                labelsBottom,
                helper
              },
              (key, keyVal) => (typeof keyVal === 'undefined' ? null : keyVal),
              2
            )}
          </pre>
        </div>
      )}
      {editable && (
        <QuestionSettingsDrawer
          question={props}
          name={name}
          type={type}
          validation={rules}
          onChange={validation => onChange({ validation })}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
        />
      )}
    </MatrixWrapper>
  );
};

export default Matrix;

const MatrixController = props => {
  const {
    name,
    error,
    options,
    rows,
    value,
    onChange,
    itemRef,
    fieldState,
    formState,
    ...restProps
  } = props;
  const parsedOptions = generateOptions(options);
  const parsedRows = generateRows({ name, rows });
  // console.log({ options, parsedOptions });
  // console.log({ rows, parsedRows });

  const cols = parsedOptions.length;
  // console.log({ parsedOptions, parsedRows });

  const rowCases = parsedRows.map(opt => opt && { ...opt, response: null });
  const [innerRows, setInnerRows] = useState(value || rowCases); // for controlled component if value

  const markRowResponse = async ({ row, response }) => {
    // console.log({ row, response });
    const newState = [...innerRows];
    const clickedOption = newState.find(item => item.value === row);
    const clickedIndex = newState.indexOf(clickedOption);
    const newRowsState = { ...clickedOption, response };
    // console.log({ newState, clickedOption, clickedIndex, newRowsState });
    newState[clickedIndex] = newRowsState;
    await setInnerRows(newState);
    await onChange(newState);
  };

  const firstWithNoResponse = () => {
    const firstNoResponse = innerRows.find(row => !row.response);
    return innerRows.indexOf(firstNoResponse);
  };
  // no responded row
  const shouldFocusRowIndex = firstWithNoResponse();
  return (
    <MatrixItems aria-labelledby={name} errorState={error}>
      <MatrixGroup className="heading-row">
        <div />
        <MatrixOptions cols={cols}>
          {parsedOptions.map(opt => (
            <div
              key={opt.id}
              // key={opt.value}
              className="column-label"
            >
              {opt.label}
            </div>
          ))}
        </MatrixOptions>
      </MatrixGroup>
      {innerRows.map((row, rowIndex) => (
        <MatrixGroup
          key={row.value}
          className="item-row"
          rowError={shouldFocusRowIndex === rowIndex && error}
        >
          <div id={row.value} className="row-label">
            {row.label}
          </div>
          <MatrixOptions cols={cols} aria-labelledby={row.value}>
            {parsedOptions.map((opt, i) => {
              // console.log({ fieldState, formState });
              const focusRow = shouldFocusRowIndex === rowIndex;
              const firstButton = i === 0;
              const firstItem = focusRow && firstButton;
              const focusProps = firstItem ? { ref: itemRef } : {};
              const isChecked = row.response === opt.value;
              return (
                <div
                  key={opt.id}
                  // key={key}
                  className="matrix-button-wrapper"
                >
                  <button
                    {...focusProps}
                    type="button"
                    className="matrix-option"
                    aria-label={opt.label}
                    data-checked={isChecked}
                    onClick={() => markRowResponse({ row: row.value, response: opt.value })}
                  >
                    <span className={`radio-icon${isChecked ? ' checked' : ''}`}>
                      <Icon size={24} icon={isChecked ? iconChecked : iconUnchecked} />
                    </span>
                    <span className={`radio-opt${isChecked ? ' checked' : ''}`}>{opt.label}</span>
                  </button>
                </div>
              );
            })}
          </MatrixOptions>
        </MatrixGroup>
      ))}
    </MatrixItems>
  );
};
