import { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import {
  // Slider,
  SliderInput,
  SliderTrack,
  SliderRange,
  SliderHandle,
  SliderMarker
} from '@reach/slider';
import { SliderWrapper } from '@/styles/questions.styles';
import QuestionTypeSelector from './Shared/QuestionTypeSelector';
import QuestionSettingsDrawer from './Shared/QuestionSettingsDrawer';
// import QuestionEditorHeader from './Shared/QuestionEditorHeader';
import QuestionError from './Shared/QuestionError';

import '@reach/slider/styles.css';

const SliderComponent = props => {
  const {
    type,
    name,
    control,
    rules = {},
    helper,
    error,
    value,
    label,
    title,
    min = 0,
    max = 100,
    step = 10,
    showValues = true,
    markers = [],
    labelsTop = [],
    isVisible = true,
    disabled,
    editable,
    onChange,
    onDuplicate,
    onDelete
  } = props;

  // handle automatic title input focus on selected question
  const titleRef = useRef();
  useEffect(() => {
    if (editable) {
      if (titleRef.current) {
        titleRef.current.focus();
      }
    }
  }, [editable]);

  if (!isVisible) {
    return null;
  }

  return (
    <SliderWrapper className="slider-question" errorState={error}>
      {/* {editable && <QuestionEditorHeader name={name} onChange={val => onChange({ name: val })} />} */}

      {editable ? (
        <div className="question-info-select">
          <input
            ref={titleRef}
            className="qst-title-input"
            placeholder="Write here your question title..."
            defaultValue={label}
            onChange={e => onChange({ title: e.target.value })}
          />{' '}
          <QuestionTypeSelector
            name={name}
            type={type}
            onChange={val => onChange({ type: val.type })}
          />
        </div>
      ) : (
        <div
          id={name}
          className={`qst-title${label ? '' : ' placeholder'}${title ? '' : ' empty'}`}
        >
          {label || 'Write here your question title...'}
          {rules?.required?.value === true && (
            <span title="Required" aria-label="Required" className="required-mark">
              *
            </span>
          )}
        </div>
      )}

      {editable && (
        <input
          className="qst-helper-input"
          placeholder="Question footer, note or helper text..."
          defaultValue={typeof helper === 'function' ? helper() : helper}
          onChange={e => onChange({ helper: e.target.value })}
        />
      )}

      {!!labelsTop.length && (
        <div className="question-labels top">
          <div className="label left">{labelsTop[0]}</div>
          <div className="label center">{labelsTop[1]}</div>
          <div className="label right">{labelsTop[2]}</div>
        </div>
      )}

      {!editable && (
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={value || null}
          render={({ field }) => (
            <>
              <SliderInput
                // {...props}
                aria-labelledby={name}
                data-invalid={error ? 'true' : 'false'}
                min={min}
                max={max}
                step={step}
                value={field.value || min}
                onChange={val => field.onChange(val)}
                disabled={disabled}
              >
                <SliderTrack>
                  <SliderRange />
                  {markers.map(markerValue => (
                    <SliderMarker key={`${name}_${markerValue}`} value={markerValue} />
                  ))}
                  <SliderHandle {...field} />
                </SliderTrack>
              </SliderInput>
              {showValues && (
                <div className="question-labels bottom">
                  <div className="label left">{min}</div>
                  <div className="label center">{field.value}</div>
                  <div className="label right">{max}</div>
                </div>
              )}
            </>
          )} // props contains: onChange, onBlur and value
        />
      )}

      {!editable && helper && (
        <span className="form-text question-helper">
          {typeof helper === 'function' ? helper() : helper}
        </span>
      )}
      <QuestionError error={error} />
      {editable && (
        <div>
          <pre>
            {JSON.stringify(
              { name, type, label, min, max, step, markers, showValues, rules, labelsTop, helper },
              (key, keyVal) => (typeof keyVal === 'undefined' ? null : keyVal),
              2
            )}
          </pre>
        </div>
      )}
      {editable && (
        <QuestionSettingsDrawer
          question={props}
          name={name}
          type={type}
          validation={rules}
          onChange={validation => onChange({ validation })}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
        />
      )}
    </SliderWrapper>
  );
};

export default SliderComponent;
// https://reach.tech/slider
