import { useState } from 'react';
import { customAlphabet } from 'nanoid';
import { Icon } from 'react-icons-kit';
// import { bars } from 'react-icons-kit/fa/bars';
// import { drag } from 'react-icons-kit/ionicons/drag';
import { grabber } from 'react-icons-kit/oct/grabber';
import { DragDropContext, Droppable, Draggable, resetServerContext } from 'react-beautiful-dnd';
import { useBuilderContext } from '@/contexts/builder-context';
import { QuestionEditorElementWrapper } from '@/styles/questions.styles';
import MDText from './MDText';
import Input from './Input';
import Textarea from './Textarea';
import Date from './Date';
import CheckboxGroup from './Checkbox';
// import Radio from './Radio';
import Radio from './RadioButtons';
import Rating from './Rating';
import Select from './Select';
import Slider from './Slider';
import Sentiment from './Sentiment';
import Buttons from './Buttons';
import Matrix from './Matrix';
import Hidden from './Hidden';
import { validationRules } from './validation';

const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghigklmnopqrstuvwxyz', 3);

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const QuestionEditorWrapper = props => {
  const {
    pageId,
    questions,
    pageIndex,
    control,
    errors,
    responses,
    disabled,
    selected,
    setSelected
  } = props;

  const { model, setModel } = useBuilderContext();

  const handleModelQuestionsUpdate = instructions => {
    const { page, questionID, values } = instructions;
    // console.log({ model, setModel, instructions });
    const questionPage = { ...model.pages[page] };
    const pageElements = [...questionPage.elements];
    const currentQuestion = pageElements.find(el => el.id === questionID);
    const questionIndex = pageElements.indexOf(currentQuestion);
    // console.log({ questionPage });
    // console.log({ pageElements });
    // console.log({ questionIndex, currentQuestion });
    // override question
    const newQuestionProps = { ...currentQuestion, ...values };
    // console.log({ newQuestionProps });
    // override elements
    pageElements[questionIndex] = newQuestionProps;
    // override page
    questionPage.elements = pageElements;
    // override model
    const modelClone = { ...model };
    modelClone.pages[page] = questionPage;
    // console.log({ modelClone });
    setModel(modelClone);
  };

  const handleModelQuestionsDuplicate = ({ instructions, duplicated }) => {
    const { page, questionID } = instructions;
    // console.log({ instructions, duplicated });
    const questionPage = { ...model.pages[page] };
    const pageElements = [...questionPage.elements];
    const currentQuestion = pageElements.find(el => el.id === questionID);
    const questionIndex = pageElements.indexOf(currentQuestion);
    // splice mutates array and return removed elements
    const itemsToRemove = 0;
    // splice at index, items to remove and items to add:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice
    pageElements.splice(questionIndex + 1, itemsToRemove, duplicated);
    // console.log({ pageElements, removedElements });
    // override page
    questionPage.elements = [...pageElements];
    // override model
    const modelClone = { ...model };
    modelClone.pages[page] = questionPage;
    // console.log({ modelClone });
    setModel(modelClone);
  };

  const handleModelQuestionsDelete = instructions => {
    const { page, questionID } = instructions;
    // console.log({ model, setModel, instructions });
    const questionPage = { ...model.pages[page] };
    const pageElements = [...questionPage.elements];
    const currentQuestion = pageElements.find(el => el.id === questionID);
    const questionIndex = pageElements.indexOf(currentQuestion);
    // splice mutates array and return removed elements
    const itemsToRemove = 1;
    // splice at index, items to remove and items to add:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice
    pageElements.splice(questionIndex, itemsToRemove);
    // console.log({ pageElements, removedElements });
    // override page
    questionPage.elements = [...pageElements];
    // override model
    const modelClone = { ...model };
    modelClone.pages[page] = questionPage;
    // console.log({ modelClone });
    setModel(modelClone);
  };

  const handleQuestionChange = ({ questionID, values }) => {
    // console.log({ questionID, values });
    const updateInstructions = {
      page: pageIndex,
      questionID,
      values
    };
    handleModelQuestionsUpdate(updateInstructions);
  };

  const handleQuestionDuplicate = qst => {
    // console.log({ qst });
    const original = { ...qst };
    // remove layout trash
    delete original.control;
    delete original.disabled;
    delete original.editable;
    delete original.index;
    delete original.onChange;
    delete original.onDelete;
    delete original.onDuplicate;
    delete original.selected;
    delete original.value;
    delete original.error;
    const duplicateInstructions = { page: pageIndex, questionID: original.id };
    const duplicated = {
      ...original,
      name: `${original.name}_${nanoid(2)}`,
      id: nanoid(3)
    };
    // console.log({ duplicated, duplicateInstructions });
    handleModelQuestionsDuplicate({
      instructions: duplicateInstructions,
      duplicated
    });
  };

  const handleQuestionDelete = qst => {
    const deleteInstructions = { page: pageIndex, questionID: qst.id };
    // console.log({ deleteInstructions });
    handleModelQuestionsDelete(deleteInstructions);
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const newPageElements = reorder(questions, result.source.index, result.destination.index);
    // console.log({ newPageElements });
    const questionPage = { ...model.pages[pageIndex] };
    // override page
    questionPage.elements = [...newPageElements];
    // override model
    const modelClone = { ...model };
    modelClone.pages[pageIndex] = questionPage;
    // console.log({ modelClone });
    setModel(modelClone);
  };
  resetServerContext();
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`droppable_${pageId}`}>
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {questions.map((question, index) => {
              const {
                id,
                type,
                name,
                title,
                options = [],
                validation = {},
                ratingMax = 5,
                step = 1,
                markers = [],
                checkAll = false,
                defaultValue,
                ...restProps
              } = question;

              // const parsedLabel = typeof title === 'function' ? title({}) : title;
              const parsedLabel = title;

              const commonProps = {
                type,
                name,
                label: parsedLabel,
                title,
                options,
                control,
                rules: validationRules(validation),
                value: responses[name] || defaultValue,
                error: errors[name],
                editable: selected === id,
                disabled,
                checkAll,
                ratingMax,
                step,
                markers,
                onChange: values => handleQuestionChange({ questionID: id, values }),
                onDuplicate: handleQuestionDuplicate,
                onDelete: handleQuestionDelete
              };

              return (
                <DraggableQuestionItem
                  key={question.id}
                  id={question.id}
                  index={index}
                  selected={selected === id}
                  setSelected={setSelected}
                  {...restProps}
                  {...commonProps}
                />
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const DraggableQuestionItem = props => {
  const { id, index, selected, setSelected, checkAll, type, inputType, step, ratingMax } = props;
  const [hovered, setHovered] = useState(false);

  const questionProps = { ...props };
  delete questionProps.setSelected;
  delete questionProps.checkAll;
  delete questionProps.inputType;
  delete questionProps.step;
  delete questionProps.ratingMax;

  let element;

  if (type === 'input') {
    element = <Input inputType={inputType} {...questionProps} />;
  }
  if (type === 'mdtext') {
    element = <MDText {...questionProps} />;
  }
  if (type === 'textarea') {
    element = <Textarea {...questionProps} />;
  }
  if (type === 'date') {
    element = <Date {...questionProps} />;
  }
  if (type === 'dropdown') {
    element = <Select {...questionProps} />;
  }
  if (type === 'rating') {
    element = <Rating ratingMax={ratingMax} {...questionProps} />;
  }
  if (type === 'slider') {
    element = <Slider step={step} {...questionProps} />;
  }
  if (type === 'radio') {
    element = <Radio {...questionProps} />;
  }
  if (type === 'checkbox') {
    element = <CheckboxGroup checkAll={checkAll} {...questionProps} />;
  }
  if (type === 'sentiment') {
    element = <Sentiment {...questionProps} />;
  }
  if (type === 'buttons') {
    element = <Buttons {...questionProps} />;
  }
  if (type === 'matrix') {
    element = <Matrix {...questionProps} />;
  }
  if (type === 'hidden') {
    element = <Hidden {...questionProps} />;
  }
  return (
    <Draggable key={id} draggableId={`${id}`} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{ ...provided.draggableProps.style }}
        >
          <QuestionEditorElementWrapper
            isDragging={snapshot.isDragging}
            hovered={hovered}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => setSelected(id)}
          >
            <div {...provided.dragHandleProps} className="grab-icon">
              <Icon size={20} icon={grabber} />
            </div>
            {selected && <div className="select-feedback" />}
            {element}
          </QuestionEditorElementWrapper>
        </div>
      )}
    </Draggable>

    // <QuestionItem
    //   key={id}
    //   id={id}
    //   index={qstIndex}
    //   element={element}
    //   selected={selected === id}
    //   setSelected={setSelected}
    // />
  );
};

// const QuestionItem = ({ element, id, index, selected, setSelected }) => {
//   const [hovered, setHovered] = useState(false);
//   // console.log({ element });
//   // const stageRefs = element?.props?.control?.fieldsRef;
//   // console.log({ stageRefs });
//   // const currentInput = stageRefs.current[id];
//   // console.log({ currentInput });
//   // if (selected) {
//   //   currentInput?.ref?.focus();
//   // }
//   return (
//     <Draggable key={id} draggableId={id} index={index}>
//       {(provided, snapshot) => (
//         <QuestionEditorElementWrapper
//           ref={provided.innerRef}
//           {...provided.draggableProps}
//           style={{ ...provided.draggableProps.style }}
//           isDragging={snapshot.isDragging}
//           hovered={hovered}
//           onMouseEnter={() => setHovered(true)}
//           onMouseLeave={() => setHovered(false)}
//           onClick={() => setSelected(id)}
//         >
//           <div {...provided.dragHandleProps} className="grab-icon">
//             <Icon size={20} icon={grabber} />
//           </div>
//           {selected && <div className="select-feedback" />}
//           {element}
//           {/** <button onClick={() => currentInput?.ref?.focus()}>Focus</button> */}
//         </QuestionEditorElementWrapper>
//       )}
//     </Draggable>
//   );
// };

export default QuestionEditorWrapper;
