import { useState, useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import dynamic from 'next/dynamic';
import { InputWrapper, CheckboxWrapper } from '@/styles/questions.styles';
import { Icon } from 'react-icons-kit';
import { helpCircle } from 'react-icons-kit/feather/helpCircle';
import QuestionError from './Shared/QuestionError';
import QuestionTypeSelector from './Shared/QuestionTypeSelector';
import QuestionSettingsDrawer from './Shared/QuestionSettingsDrawer';
// import QuestionEditorHeader from './Shared/QuestionEditorHeader';

import { Checkbox } from './Checkbox';
// import Tippy from '@/components/Tooltip';
const Tippy = dynamic(() => import('@/components/Tooltip'), { ssr: false });

// import QuestionValidationOptions from './Shared/QuestionValidationOptions';

const Input = props => {
  const {
    type,
    inputType = 'text',
    name,
    label,
    title,
    control,
    rules = {},
    helper,
    error,
    value,
    isVisible = true,
    editable,
    onChange,
    onDuplicate,
    onDelete,
    ...restProps
  } = props;
  // console.log({ error });
  // console.log({restProps})

  // handle automatic title input focus on selected question
  const titleRef = useRef();
  useEffect(() => {
    if (editable) {
      if (titleRef.current) {
        titleRef.current.focus();
      }
    }
  }, [editable]);

  if (!isVisible) {
    return null;
  }
  const htmlMin = rules?.min?.value;
  const htmlMax = rules?.max?.value;

  const numberRange =
    inputType === 'number'
      ? {
          min: htmlMin,
          max: htmlMax
        }
      : {};

  return (
    <InputWrapper className="input-question" errorState={error}>
      {/* {editable && <QuestionEditorHeader name={name} onChange={val => onChange({ name: val })} />} */}

      {editable ? (
        <div className="question-info-select">
          <input
            ref={titleRef}
            className="qst-title-input"
            placeholder="Write here your question title..."
            defaultValue={label}
            onChange={e => onChange({ title: e.target.value })}
          />{' '}
          <QuestionTypeSelector
            name={name}
            type={type}
            onChange={val => onChange({ type: val.type })}
          />
        </div>
      ) : (
        <div className={`qst-title${label ? '' : ' placeholder'}${title ? '' : ' empty'}`}>
          {label || 'Write here your question title...'}
          {rules?.required?.value === true && (
            <span title="Required" aria-label="Required" className="required-mark">
              *
            </span>
          )}
        </div>
      )}

      {editable && (
        <input
          className="qst-helper-input"
          placeholder="Question footer, note or helper text..."
          defaultValue={typeof helper === 'function' ? helper() : helper}
          onChange={e => onChange({ helper: e.target.value })}
        />
      )}

      {editable && (
        <InputTypeSelect
          name={name}
          inputType={inputType}
          onChange={val => onChange({ inputType: val })}
        />
      )}

      {!editable && (
        <label htmlFor={name} aria-label={title}>
          <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={value || ''}
            render={({ field }) => (
              <input
                type={inputType}
                id={name}
                name={name}
                data-invalid={error ? 'true' : 'false'}
                {...numberRange}
                {...restProps}
                {...field}
              />
            )} // props contains: onChange, onBlur and value
          />
        </label>
      )}

      {!editable && helper && (
        <span className="form-text question-helper">
          {typeof helper === 'function' ? helper() : helper}
        </span>
      )}
      <QuestionError error={error} />
      {/**
            {editable && (
        <div>
          <pre>
            {JSON.stringify(
              { name, type, inputType, label, rules, helper },
              (key, keyVal) => (typeof keyVal === 'undefined' ? null : keyVal),
              2
            )}
          </pre>
        </div>
      )}
       */}

      {editable && (
        <QuestionSettingsDrawer
          question={props}
          name={name}
          type={type}
          validation={rules}
          onChange={validation => onChange({ validation })}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
        />
      )}
      {/* {editable && (
        <QuestionValidationOptions name={name} type={type} onChange={val => console.log(val)} />
      )} */}
    </InputWrapper>
  );
};

export default Input;

const InputTypeSelect = ({ name, inputType, onChange }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleCheck = newType => {
    // if same, toggle to initial 'text' type
    if (newType === inputType) {
      return onChange('text');
    }
    onChange(newType);
  };

  return (
    <div>
      <div className="options-header">
        <div className="options-label">
          <div>Options</div>
        </div>
        <div className="options-desc">
          Select if input variant{' '}
          <span className="help">
            <Tippy
              content={
                <div className="help-tooltip">
                  <div>
                    Use to restrict answers type. In other words: <br />
                    'Number' only allows number characters.
                    <br /> 'Email' only allows a correctly formatted email
                  </div>
                </div>
              }
              visible={showTooltip}
              onClickOutside={() => setShowTooltip(false)}
            >
              <button type="button" onClick={() => setShowTooltip(!showTooltip)}>
                <Icon size={12} icon={helpCircle} />
              </button>
            </Tippy>
          </span>
        </div>
      </div>
      <div className="inputtype-opt">
        <CheckboxWrapper className="inputtype-check-wrapper">
          <label>
            <Checkbox
              id={`${name}-inputtype-number`}
              name={`${name}-inputtype`}
              value="number"
              checked={inputType === 'number'}
              onChange={() => handleCheck('number')}
            />
            <div className="checkbox-label">Is Number</div>
          </label>
          <label>
            <Checkbox
              id={`${name}-inputtype-email`}
              name={`${name}-inputtype`}
              value="email"
              checked={inputType === 'email'}
              onChange={() => handleCheck('email')}
            />
            <div className="checkbox-label">Is Email</div>
          </label>

          {/**
          <label>
            <Checkbox
              id={`${name}-inputtype`}
              name={`${name}-inputtype`}
              value="tel"
              checked={inputType === 'tel'}
              onChange={() => handleCheck('tel')}
            />
            Is Phone
          </label>
           */}

          <label>
            <Checkbox
              id={`${name}-inputtype-url`}
              name={`${name}-inputtype`}
              value="url"
              checked={inputType === 'url'}
              onChange={() => handleCheck('url')}
            />
            <div className="checkbox-label">Is Url</div>
          </label>
        </CheckboxWrapper>
      </div>
    </div>
  );
};
// const INPUT_QST = {
//   id: 'rxdtcfygvubhijn',
//   type: 'input',
//   inputType: 'text', // default "text"
//   name: 'input-base',
//   title: 'What is it?',
//   validation: {
//     minLength: {
//       value: 5,
//       message: 'Por favor, ingresa mas de 5 caracteres'
//     },
//     required: 'Por favor completa esto'
//   }
// };
// const NUMBER_QST = {
//   id: 'h678657hjklnkbjh',
//   type: 'input',
//   inputType: 'number', // default "text"
//   name: 'input-number-base',
//   title: 'Pick a number',
//   min: 0, // for html input params
//   max: 10,
//   validation: {
//     required: 'Por favor completa esto',
//     min: {
//       value: 0,
//       message: 'Min value is: 0'
//     },
//     max: {
//       value: 10,
//       message: 'Max value is: 10'
//     }
//   },
//   helper: 'Valor de 0 a 10'
// };
